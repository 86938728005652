<template>
  <div class="page-swiper">
    <template v-if="picList.length > 0">
      <swiper id="swiper" :observer="true" :observerParents="true" :slides-per-view="1"
        :pagination="{ clickable: true }" :autoplay="{ delay: 2500, disableOnInteraction: false }" :loop="true"
        :space-between="50" :modules="modules" class="swiper">
        <template v-for="item in picList" :key="item">
          <swiper-slide class="swiper-item">
            <div class="img-box">
              <img :src="item" alt />

            </div>
          </swiper-slide>
        </template>
      </swiper>
    </template>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper.min.css"
import { Autoplay, Pagination, Navigation } from 'swiper'
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css'
import { UPLOAD_BASE_URL } from '@/common/constant';

export default defineComponent({
  props: {
    picList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {


    return {
      modules: [Autoplay, Pagination, Navigation],
      UPLOAD_BASE_URL
    }

  }
})
</script>

<style scoped lang="less">
.page-swiper {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .swiper {
    width: 100%;

    .swiper-item {

      .img-box {
        img {
          width: 100%;
        }
      }

      &:deep(.swiper-slide) {}

    }
  }

  .carousel {
    width: 100%;
  }
}
</style>




